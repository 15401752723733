<template>
  <modal-2 size="md" ref="modal">
    <template slot="title">
      {{ isEditing ? "Editar Filtro" : "Añadir Filtro" }}
    </template>

    <custom-form @submit="onSubmit">
      <custom-input
        v-model="newFilter.name"
        label="Nombre"
        :rules="
          `required|excluded:${filters
            .filter(f => newFilter.id !== f.id)
            .map(f => f.name)}`
        "
      />

      <div class="flex justify-between">
        <!-- <checkbox
          v-model="newFilter.orgHierarchy"
          label="Pertenece a la jerarquía organizacional"
        /> -->

        <checkbox
          v-model="newFilter.hasParent"
          @change="resetOptions"
          label="Tiene un padre"
        />
      </div>

      <custom-select
        v-if="newFilter.hasParent"
        v-model="newFilter.parentId"
        rules="required"
        label="Columna Padre"
        :options="filters.map(f => ({ id: f.id, name: f.name })) || []"
      />

      <h4>Opciones</h4>

      <custom-button variant="secondary" :fullWidth="true" @click="addOption">
        <font-awesome-icon icon="plus" slot="icon" />
        Agregar Opción
      </custom-button>

      <!-- <textarea @paste="onPaste"></textarea> -->
      <!--  -->

      <div
        v-for="(option, index) in newFilter.options"
        :key="index"
        class="flex"
      >
        <custom-input
          v-model="option.name"
          :label="`Opción ${index + 1}`"
          :rules="`required|excluded:${getUsedOptionNames(index)}`"
        />

        <custom-select
          v-if="newFilter.parentId"
          v-model="option.parentOption"
          label="Opción Padre"
          rules="required"
          :options="parentOptions"
        />

        <icon-button
          icon="times"
          label="Delete option"
          variant="secondary"
          @click="deleteOption(index)"
        />
      </div>

      <template slot="buttons">
        <custom-button
          v-if="isEditing && !survey.active"
          variant="secondary"
          @click="onDelete"
          :disabled="isLoading || isDeleteLoading"
        >
          <font-awesome-icon
            v-if="!isDeleteLoading"
            icon="trash-alt"
            slot="icon"
          />
          {{ isDeleteLoading ? "Eliminando..." : "Eliminar Filtro" }}
        </custom-button>

        <custom-button
          variant="secondary"
          @click="onCancel"
          :disabled="isLoading || isDeleteLoading"
        >
          Cancelar
        </custom-button>
        <custom-button
          type="submit"
          variant="primary"
          :disabled="isLoading || isDeleteLoading"
        >
          {{
            isEditing
              ? isLoading
                ? "Guardando..."
                : "Guardar"
              : isLoading
              ? "Agregando..."
              : "Agregar"
          }}
        </custom-button>
      </template>
    </custom-form>

    <confirm-dialogue ref="confirmDialogue" />
  </modal-2>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Checkbox from "../../components/Checkbox.vue";
import ConfirmDialogue from "../../components/ConfirmDialogue.vue";
import CustomButton from "../../components/CustomButton.vue";
import CustomForm from "../../components/CustomForm.vue";
import CustomInput from "../../components/CustomInput.vue";
import CustomSelect from "../../components/CustomSelect.vue";
import IconButton from "../../components/IconButton.vue";
import Modal2 from "../../components/Modal2.vue";

export default {
  name: "FilterModal",

  components: {
    Modal2,
    CustomForm,
    CustomInput,
    Checkbox,
    CustomSelect,
    IconButton,
    CustomButton,
    ConfirmDialogue
  },

  data: () => ({
    newFilter: {
      name: "",
      orgHierarchy: false,
      hasParent: false,
      parentId: "",
      options: []
    },
    isEditing: false,
    isLoading: false,
    isDeleteLoading: false
  }),

  computed: {
    ...mapState({
      survey: state => state.survey,
      filters: state => state.filters.filters
    }),

    parentOptions() {
      return this.newFilter.parentId
        ? this.filters
            .find(f => f.id === this.newFilter.parentId)
            .options.map(o => o.name)
        : [];
    }
  },

  methods: {
    ...mapActions("filters", ["addFilter", "updateFilter", "deleteFilter"]),

    ...mapMutations(["setAlert"]),

    getUsedOptionNames(index) {
      return !this.newFilter.orgHierarchy
        ? this.newFilter.options.filter((o, i) => i !== index).map(o => o.name)
        : this.filters
            .filter(({ orgHierarchy }) => orgHierarchy)
            .reduce((opts, f) => {
              if (this.newFilter.id === f.id) return opts;
              return opts.concat(f.options.map(o => o.name));
            }, [])
            .concat(
              this.newFilter.options
                .filter((o, i) => i !== index)
                .map(o => o.name)
            );
    },

    add() {
      this.resetFilter();
      this.isEditing = false;
      this.$refs.modal.open();
    },

    edit(filter) {
      this.newFilter.id = filter.id;
      this.newFilter.name = filter.name;
      this.newFilter.orgHierarchy = filter.orgHierarchy;
      this.newFilter.hasParent = filter.hasParent;
      this.newFilter.parentId = filter.parentId;
      this.newFilter.options = filter.options;
      this.isEditing = true;
      this.$refs.modal.open();
    },

    close() {
      this.$refs.modal.close();
    },

    resetFilter() {
      this.newFilter = {
        name: "",
        orgHierarchy: false,
        hasParent: false,
        parentId: "",
        options: []
      };
    },

    async onSubmit() {
      try {
        this.isLoading = true;

        this.newFilter.name = this.newFilter.name.trim();
        this.newFilter.options = this.newFilter.options.map(o => ({
          ...o,
          name: o.name.trim()
        }));

        if (this.isEditing) await this.updateFilter(this.newFilter);
        else await this.addFilter(this.newFilter);

        this.setAlert({
          state: "success",
          message: this.isEditing
            ? "Se actualizó el filtro"
            : "Se creó el filtro"
        });

        this.onCancel();
      } catch (error) {
        console.log(error);

        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtalo nuevamente"
        });
      } finally {
        this.isLoading = false;
      }
    },

    onCancel() {
      this.close();

      this.resetFilter();

      this.isEditing = false;
    },

    async onDelete() {
      try {
        if (this.survey.active) return;
        const ok = await this.$refs.confirmDialogue.show({
          title: "Eliminar Filtro",
          message:
            "¿Estás seguro que deseaas eliminar este filtro? Una vez eliminado no podrás recuperarlo y se eliminarán las opciones elegidas en cada usuario.",
          isDestructive: true,
          okButton: "Sí, estoy seguro",
          cancelButton: "No, volver"
        });

        if (!ok) return;

        this.isDeleteLoading = true;

        await this.deleteFilter(this.newFilter.id);

        this.onCancel();

        this.setAlert({
          state: "success",
          message: "Filtro eliminado"
        });
      } catch (error) {
        console.log(error);

        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtalo nuevamente"
        });
      } finally {
        this.isDeleteLoading = false;
      }
    },

    addOption() {
      this.newFilter.options.push({
        name: "",
        parentOption: ""
      });
    },

    onPaste(evt) {
      evt.clipboardData
        .getData("text/plain")
        .split("\n")
        .map(text => text.replace(/(\r\n|\n|\r)/gm, ""))
        .filter(text => !!text)
        .forEach(text => {
          this.newFilter.options.push({
            name: text,
            parentOption: ""
          });
        });
    },

    deleteOption(index) {
      this.newFilter.options.splice(index, 1);
    },

    resetOptions(hasParent) {
      if (!hasParent) {
        this.newFilter.parentId = "";
        this.newFilter.options = this.newFilter.options.map(o => ({
          ...o,
          parentOption: ""
        }));
      }
    }
  }
};
</script>

<style scoped>
.flex {
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
}

.checkbox {
  flex-basis: 45%;
}
</style>
